import React from "react";
import "./ButtonUpdate.css";

export const Updates = ({ updateList }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="actualization-container">
      <h3>Detalle últimas Actualizaciones</h3>
      <ul>
        {updateList.map((update) => (
          <li key={update.id_update}>
            <p>
              <strong>{update?.typeUpdate?.type_name || ""}:</strong> {update.description}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p>
                <strong>Fecha:</strong> {formatDate(update.create_at)}
              </p>
              <p>
                <strong >Version: </strong>
                {update?.version || ""}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
