import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import fileDownload from 'js-file-download';

const initialState = {
  loading: false,
  error: false,
  report: false,
  filters_data: {},
  data: {},
  parametros: {},
};

const slice = createSlice({
  name: "venta",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    venta(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.data,
        ventas: action.payload.ventas.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
    },
    year(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        years: action.payload.years.map((x) => {
          return { ...x, key: x.year };
        }),
      };
    },
    branchoffice(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        branchoffices: action.payload.branchoffices.map((x) => {
          return { ...x, key: x.branchoffice };
        }),
      };
    },
    dealer(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        dealers: action.payload.dealers.map((x) => {
          return { ...x, key: x.dealer };
        }),
      };
    },
    brand(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        brandsF: action.payload.brandsF.map((x) => {
          return { ...x, id_marca: x.id_marca, key: x.value };
        }),
      };
    },
    dashboard(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.filters_data,
        dashboardItems: action.payload.dashboard
      };
    },
    report(state) {
      state.loading = false;
      state.report = true;
    },
    documentTypes(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        documentTypesF: action.payload.documentTypesF.map((x) => {
          return { ...x, code: x.codigo, key: x.codigo };
        }),
      };
    },
    setParametros(state, action) {
      state.loading = false;
      state.parametros[action.payload.categoria] = action.payload.parametros;
    },
  },
});

export default slice.reducer;

export function getVenta({ page = 1, count = 10, filters = {}, orderField = {}, orderSort }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta?page=${page}&count=${count}`;
      for (const property in filters) {
        url += `&${property}=${filters[property]}`;
      }

      if (orderField && orderSort) {
        for (const field in orderField) {
          const fieldValue = orderField[field];
          const orderValue = orderSort;
          if (fieldValue !== 0) {
            url += `&orderField=${field}&orderSort=${orderValue}`;
          }
        }
      }
      const response = await axios.get(url);
      dispatch(slice.actions.venta(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboard({ year = '', month = '', branchoffice = '', dealer = '', trimester = '', bussinesId = '', brands = '', dealersO = '', brand = '' }) {
  const filters = { ano_doc: year, mes_doc: month, branchoffice_doc: branchoffice, dealer_doc: dealer, trimester_doc: trimester, bussinesId, brands, dealersO, brand };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/dashboard?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dashboard(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReport({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/report?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const result = await axios.get(url, { responseType: 'blob' });
      fileDownload(result.data, `ventas_${new Date().getTime()}.xlsx`);
      dispatch(slice.actions.report());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getYears({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/years?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.year(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchOffices({ dealer = '', brands = '', dealersO = '' }) {
  const filters = { dealer, brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/branchoffices?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.branchoffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDealers({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/dealers?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dealer(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrands({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/brands?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brand(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchOfficesVW({ dealer = '', brands = '', dealersO = '' }) {
  const filters = { dealer, brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/branchofficesVW?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.branchoffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDealersVW({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/dealersVW?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dealer(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrandsVW({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/brandsVW?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brand(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDocumentTypesVW() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/venta/documentTypesVW`;
      const response = await axios.get(url);
      dispatch(slice.actions.documentTypes(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getParametros(categoria) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/venta/parametros`);
      dispatch(slice.actions.setParametros({ categoria, parametros: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}