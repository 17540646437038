import React, { useEffect, useState } from "react";
import { UpdateIcon } from "../updatesComponent/UpdateIcon";
import { Button, Tooltip, Modal } from "antd";
import "../updatesComponent/ButtonUpdate.css";
import { getUpdates } from "../../redux/slices/updates";
import { useDispatch, useSelector } from "react-redux";
import { Updates } from "../updatesComponent/Updates";

export const ButtonUpdates = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const updateList = useSelector((state) => state.updates.data.update || []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const footerModal = (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "center",
  //     }}
  //   >
  //   </div>
  // )

  useEffect(() => {
    dispatch(getUpdates());
  }, [dispatch]);

  useEffect(() => {
    setIsVisible(updateList.length > 0);
  }, [updateList]);

  const title = (
    <div className="carousel-title">
      <div
        className="carousel-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          fontSize: "20px",
        }}
      >
        <UpdateIcon style={{ fill: "rgb(0,21,41)", marginRight: "10px" }} />
        Actualizaciones
      </div>
    </div>
  );

  return isVisible ? (
    <div
      style={{
        marginRight: "20px",
        marginTop: "8px",
      }}
    >
      <Tooltip
        title="Actualizaciones"
        placement="topLeft"
        style={{ marginRight: "10px" }}
      >
        <Button
          onClick={showModal}
          className="animated-button"
          style={{ backgroundColor: "transparent", border: 0 }}
        >
          <UpdateIcon style={{ fill: "#FFFFFF" }} />
        </Button>
      </Tooltip>
      <Modal
        title={title}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={500}
        bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
        style={{ borderRadius: "12px" }}
        className="custom-modal"
      >
        <Updates updateList={updateList} />
      </Modal>
    </div>
  ) : null;
};
