import { createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {},
};

const slice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    stock(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        stock: action.payload.stock.map((x) => {
          return { ...x, key: x.id };
        }),
      };
    },
    report(state) {
      state.loading = false;
      state.report = true;
    },
    branchoffice(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        branchoffices: action.payload.branchoffices.map((x) => {
          return { ...x, key: x.branchoffice };
        }),
      };
    },
    dealer(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        dealers: action.payload.dealers.map((x) => {
          return { ...x, key: x.dealer };
        }),
      };
    },
    brand(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        brandsF: action.payload.brandsF.map((x) => {
          return { ...x, id_marca: x.id_marca, key: x.value };
        }),
      };
    },
  },
});

export default slice.reducer;

export function getStock({ page = 1, count = 10, filters = {}, orderField = {}, orderSort }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/stock?page=${page}&count=${count}`;
      for (const property in filters) {
        url += `&${property}=${filters[property]}`;
      }

      if (orderField && orderSort) {
        for (const field in orderField) {
          const fieldValue = orderField[field];
          const orderValue = orderSort;
          if (fieldValue !== 0) {
            url += `&orderField=${field}&orderSort=${orderValue}`;
          }
        }
      }
      const response = await axios.get(url);
      dispatch(slice.actions.stock(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReport({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/stock/report?`;
      let count = 0;
      for (const key in filters) {
        url +=
          count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`;
        count++;
      }
      const result = await axios.get(url, { responseType: "blob" });
      fileDownload(result.data, `stock_${new Date().getTime()}.xlsx`);
      dispatch(slice.actions.report());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchOffices({ dealer = '', brands = '', dealersO = '' }) {
  const filters = { dealer, brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/stock/branchoffices?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.branchoffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDealers({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/stock/dealers?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dealer(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrands({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/stock/brands?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brand(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}